import React from 'react';

// components
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

import { useTranslation } from 'react-i18next';

import C2015K7Image from '../../images/data/c_2015_k7.gif';
import P2016P5Image from '../../images/data/p_2016_p5.gif';

const Comets = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('彗星の詳細')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="contents_text">
            <div className="data_analysis">
              <h4 className="data_analysis-title">
                {t('COIASで発見された彗星')}
              </h4>
              <p>
                {t(
                  'COIASで発見された天体のうち、彗星であることが確認され公表されたものを本ページでご紹介します。',
                )}
              </p>
              <h5 className="mg-t_sm mg-b_md f-s24_w700">C/2015 K7</h5>
              <img
                alt=""
                src={C2015K7Image}
                width="400px"
                height="400px"
                className="mg-b_xs"
              />
              <ul>
                <li className="list_hyphen">
                  <a
                    href="http://www.cbat.eps.harvard.edu/iau/cbet/005400/CBET005484.txt"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    CBET 5484: COMET C/2015 K7
                  </a>
                </li>
                <li className="list_hyphen">
                  <a
                    href="https://www.minorplanetcenter.net/mpec/K24/K24Y66.html"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    MPEC 2024-Y66: COMET C/2015 K7
                  </a>
                </li>
                <li className="list_hyphen">
                  <a
                    href="https://www.minorplanetcenter.net/db_search/show_object?object_id=C/2015%20K7"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    MPC
                  </a>
                </li>
                <li className="list_hyphen">
                  <a
                    href="https://ssd.jpl.nasa.gov/tools/sbdb_lookup.html#/?sstr=C%2F2015%20K7"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    JPL Small-Body Database
                  </a>
                </li>
                <li className="list_hyphen">{t('発見日')}: 2015-05-17</li>
                <li className="list_hyphen">
                  {t('軌道の分類')}: {t('非周期彗星')}
                </li>
              </ul>
              <p>
                {i18n.language === 'en'
                  ? 'This is the first comet discovered by COIAS (Subaru Telescope has discovered several comets so far, including P/2018 V5 (Trujillo-Sheppard)). On November 26, 2024, COIAS user Mr. Yamauchi reported the comet-like object, and the COIAS development team reported it to the MPC and others.'
                  : 'COIASで発見された最初の彗星です（すばる望遠鏡では、P/2018 V5(Trujillo-Sheppard)など、これまで数天体の彗星が発見されています）。2024年11月26日にCOIASユーザーの山内様から彗星状天体の報告があり、COIAS開発チームからMPCなどへ報告を行いました。'}
              </p>
              <p>
                {i18n.language === 'en'
                  ? 'The coma and tail of C/2015 K7 were visible near perihelion between 2013 and 2015. Even at its closest approach to the Sun, the comet remained approximately 9.9 AU away, similar to the oribtal distance of Saturn.'
                  : 'C/2015 K7は、近日点付近を通過していた2013年から2015年にかけてコマや尾が見えたことが分かっています。本彗星は太陽に最も近かったときでも約9.9天文単位と土星の軌道付近までしか近づきません。'}
              </p>
              <p>
                {i18n.language === 'en'
                  ? 'Cometary activity at such a distance is rare, and thus, the result is a valuable achievement.'
                  : 'こうした遠方での彗星活動は珍しく、貴重な成果です。'}
              </p>
              <h5 className="mg-t_sm mg-b_md f-s24_w700">P/2016 P5 (COIAS)</h5>
              <img
                alt=""
                src={P2016P5Image}
                width="400px"
                height="400px"
                className="mg-b_xs"
              />
              <ul>
                <li className="list_hyphen">
                  <a
                    href="http://www.cbat.eps.harvard.edu/iau/cbet/005500/CBET005529.txt"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    CBET 5529: COMET P/2016 P5 (COIAS)
                  </a>
                </li>
                <li className="list_hyphen">
                  <a
                    href="https://www.minorplanetcenter.net/mpec/K25/K25F40.html"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    MPEC 2025-F40: COMET P/2016 P5 (COIAS)
                  </a>
                </li>
                <li className="list_hyphen">
                  <a
                    href="https://www.minorplanetcenter.net/db_search/show_object?object_id=P/2016%20P5"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    MPC
                  </a>
                </li>
                <li className="list_hyphen">
                  <a
                    href="https://ssd.jpl.nasa.gov/tools/sbdb_lookup.html#/?sstr=P%2F2016%20P5"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    JPL Small-Body Database
                  </a>
                </li>
                <li className="list_hyphen">{t('発見日')}: 2016-08-01</li>
                <li className="list_hyphen">
                  {t('軌道の分類')}: {t('木星族彗星')}
                </li>
              </ul>
              <p>
                {i18n.language === 'en'
                  ? 'P/2016 P5 (COIAS) is the first periodic comet discovered by COIAS. On March 16, 2025, a participant reported a cometary discovery in images, which the development team then reported to the Minor Planet Center.'
                  : '周期彗星P/2016 P5(COIAS)は、COIASの名前が付いた初の彗星です（COIASで初めて発見された周期彗星でもあります）。2025年3月16日、参加者の方からすばる望遠鏡の画像に彗星を発見したとのお知らせがあり、開発チームから小惑星センターに報告しました。'}
              </p>
              <p>
                {i18n.language === 'en'
                  ? "P/2016 P5 has been observed in images taken by multiple observatories from 2004 to 2023. The orbital period of the comet is about 10.2 years, which is inner Jupiter orbit. It approaches Jupiter's orbit very closely near its aphelion, so it is considered to be classified as a Jupiter family comet or a quasi-Hilda comet."
                  : 'P/2016 P5は、現在までに2004年から2023年にかけて複数の天文台の画像で確認されています。彗星は木星軌道の内側を約10.2年かけて周回しています。遠日点付近では木星の軌道にかなり近づくため、木星族彗星あるいは準ヒルダ彗星に分類されると考えられます。'}
              </p>
              <p>
                {i18n.language === 'en'
                  ? 'While the MOID (Minimum Orbital Intersection Distance) between P/2016 P5 and Jupiter is 0.00134416 AU (approximately 200,000 km), P/2016 P5 has not, and will not, closely approach Jupiter at this separation in the near past or future.'
                  : 'P/2016 P5の軌道と木星軌道の距離は、最短で0.00134416 AU（20万km程度）です(近い過去や将来において木星にここまで近づくということではありません)。'}
                <a
                  href="https://groups.io/g/comets-ml/topic/h431154_on_pccp/111764220"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {i18n.language === 'en'
                    ? 'According to Mr. Sam Deen, an amateur astronomer, '
                    : 'アマチュア天文学者Sam Deen氏によると、'}
                </a>
                {i18n.language === 'en'
                  ? 'it is thought that the comet was outside the orbit of Jupiter from 1796 to 1979 and transitioned to its current orbit due to its close encounter with Jupiter.'
                  : '彗星は1796年から1979年までは木星軌道の外側にあり、木星との接近により現在の軌道に遷移したと考えられます。'}
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Comets;
