import React from 'react';

import './ModalStyle.scss';

// plugin
import { Button, Modal } from 'react-bootstrap';

const ImportantNoticeModal = ({ show, onHide }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>お知らせ / Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body className="contents-text_only">
          <p>
            いくつかの小さな機能修正のため、2/11(火)
            21:00から1時間ほど、メンテナンスを行います。
            期間中COIASをご利用いただけませんので、予めご了承ください。
          </p>
          <p>
            To fix some subtle feature problem, we will conduct a minor
            maintenance for about 1 hour from 2/11 (Tue.) 21:00 JST. We cannot
            use COIAS during this period.
          </p>
        </Modal.Body>
        <Modal.Footer className="one_button">
          <Button variant="secondary" onClick={onHide}>
            閉じる / Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportantNoticeModal;
