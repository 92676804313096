import React from 'react';

import { FaAngleDown, FaAngleRight } from 'react-icons/fa';

import './HeaderStyle.scss';

// plugin
import { Nav, Navbar, Offcanvas } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { BsTranslate, BsTwitter } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Navbar expand="xl" className="header bg-header">
        <Navbar.Brand>
          <NavLink to="/">
            <div className="header-title f-en">
              <p className="f-c_white f-s14_w500">
                Come On! Impacting ASteroids
              </p>
              <p className="f-c_white f-s20_w700">COIAS</p>
            </div>
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-xl" />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-xl"
          aria-labelledby="offcanvasNavbarLabel-expand-xl"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-xl">
              <div className="header-title f-en">
                <p className="f-c_white f-s14_w500">
                  Come On! Impacting ASteroids
                </p>
                <p className="f-c_white f-s20_w700">COIAS</p>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="header_links">
              <NavLink to="/about_coias" className="link_style top_nav_link">
                <span className="f-en f-s16_w500">{t('COIASについて')}</span>
              </NavLink>
              <NavLink
                to="/news"
                className="link_style top_nav_link f-en f-s16_w500"
              >
                News
              </NavLink>
              <NavLink to="/guide" className="link_style top_nav_link">
                {t('使い方ガイド')}
              </NavLink>
              <div className="hovermenu_wrapper link_style top_nav_link">
                {t('データ解析状況')}
                <input type="checkbox" id="details_toggle_button" />
                <label for="details_toggle_button" className="sp_toggle">
                  <span className="when_off">
                    <FaAngleRight />
                  </span>
                  <span className="when_on">
                    <FaAngleDown />
                  </span>
                </label>
                <ul className="hover_items">
                  <li className="hover_item">
                    <NavLink to="/data_analysis" className="link_style">
                      {t('概要')}
                    </NavLink>
                  </li>
                  <li className="hover_item">
                    <NavLink
                      to="/data_analysis/candidates"
                      className="link_style"
                    >
                      {t('新天体の候補')}
                    </NavLink>
                  </li>
                  <li className="hover_item">
                    <NavLink
                      to="/data_analysis/provisional"
                      className="link_style"
                    >
                      {t('仮符号天体の詳細')}
                    </NavLink>
                  </li>
                  <li className="hover_item">
                    <NavLink
                      to="/data_analysis/numbered"
                      className="link_style"
                    >
                      {t('確定番号天体の詳細')}
                    </NavLink>
                  </li>
                  <li className="hover_item">
                    <NavLink to="/data_analysis/comets" className="link_style">
                      {t('彗星の詳細')}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="hovermenu_wrapper link_style top_nav_link">
                {t('もっと詳しく')}
                <input type="checkbox" id="details_toggle_button" />
                <label for="details_toggle_button" className="sp_toggle">
                  <span className="when_off">
                    <FaAngleRight />
                  </span>
                  <span className="when_on">
                    <FaAngleDown />
                  </span>
                </label>
                <ul className="hover_items">
                  <li className="hover_item">
                    <NavLink to="/details/procedure" className="link_style">
                      {t('小惑星を発見するには？')}
                    </NavLink>
                  </li>
                  <li className="hover_item">
                    <NavLink to="/details/dictionary" className="link_style">
                      {t('用語解説集')}
                    </NavLink>
                  </li>
                  <li className="hover_item">
                    <NavLink to="/details/faq" className="link_style">
                      {t('よくある質問')}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <NavLink to="/contact" className="link_style top_nav_link">
                {t('お問い合わせ')}
              </NavLink>
              <NavLink
                className="link_style top_nav_link"
                onClick={() => window.open('https://twitter.com/coias_t09')}
              >
                <BsTwitter size={27} />
              </NavLink>
              <NavLink
                className="link_style top_nav_link"
                onClick={() => {
                  if (i18n.language === 'en') {
                    i18n.init();
                  } else {
                    i18n.changeLanguage('en');
                  }
                  console.log(i18n.language);
                }}
              >
                <BsTranslate size={27} />
              </NavLink>
              <NavLink
                to="/login"
                className="link_style top_nav_link login_font"
              >
                <span className="login_button">{t('新規登録・ログイン')}</span>
              </NavLink>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
};

export default Header;
