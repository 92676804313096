import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';

import axios from 'axios';

// components
import Footer from '../components/Footer/Footer';

import Header from '../components/Header/Header';

import MagHistogram from '../components/MagHistogram';
import { provisionalCodeInfoList } from '../components/ProvisionalCodeInformationTable/provisionalCodeInfoList';
import { numberedObjectsInfoList } from '../components/NumberedObjectsInformationTable/NumberedObjectsInfoList';

import { useTranslation } from 'react-i18next';

const apiURL = process.env.REACT_APP_API_URL;

const DataAnalysis = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [NNewObject, setNNewObject] = useState(-1);
  const [NProvisionalCode, setNProvisionalCode] = useState(-1);
  const [NNumberedObjects, setNNumberedObjects] = useState(-1);
  const [NNeos, setNNeos] = useState(-1);
  const [NDistantObjects, setNDistantObjects] = useState(-1);
  const NComets = 2; // for now, we set this value manually
  const [magHistogramData, setMagHistogramData] = useState([]);

  const getCounts = () => {
    setNProvisionalCode(provisionalCodeInfoList.length);
    setNNumberedObjects(numberedObjectsInfoList.length);
    setNNeos(
      provisionalCodeInfoList.filter((item) => item.note.includes('NEO'))
        .length,
    );
    setNDistantObjects(
      provisionalCodeInfoList.filter((item) => item.note.includes('TNO'))
        .length,
    );
  };

  const getObjectsFigure = () => {
    axios
      .get(`${apiURL}/N_new_objects`)
      .then((res) => {
        const N_new_objects = res.data.result;

        if (N_new_objects) {
          setNNewObject(N_new_objects);
        }
      })
      .catch((e) => console.log(e));
  };

  const getMagHistogram = () => {
    axios
      .get(`${apiURL}/mag_histogram`)
      .then((res) => {
        const data = res.data.result;

        if (data) {
          setMagHistogramData(data);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getObjectsFigure();
    getMagHistogram();
    getCounts();
  }, []);

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('データ解析状況')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="contents_text">
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIASで現在までに発見された新天体候補数')}
              </p>
              <p className="number_wrap">
                <span className="number">{NNewObject}</span>
                <span className="unit">{t('個')}</span>
              </p>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">{t('仮符号天体')}</p>
              <p className="number_wrap mg-b_md">
                <span className="number">{NProvisionalCode}</span>
                <span className="unit">{t('個')}</span>
              </p>
              <p className="number_wrap mg-b_md">
                <span className="prefix">{t('彗星')}</span>
                {'  '}
                <span className="number">{NComets}</span>
                <span className="unit">{t('個')}</span>
              </p>
              <p className="number_wrap mg-b_md">
                <span className="prefix">{t('地球接近天体（NEO）')}</span>
                {'  '}
                <span className="number">{NNeos}</span>
                <span className="unit">{t('個')}</span>
              </p>
              <p className="number_wrap">
                <span className="prefix">
                  {t('遠方天体（TNO, Centaur, etc.）')}
                </span>
                {'  '}
                <span className="number">{NDistantObjects}</span>
                <span className="unit">{t('個')}</span>
              </p>
              <div className="mg-t_sm">
                <p className="f-s14_w500">
                  {t(
                    '調査の結果COIAS以前に発見された天体と同定されたものや、他の観測所の発見と判断されたものも含む。',
                  )}
                </p>
              </div>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">{t('確定番号天体')}</p>
              <p className="number_wrap">
                <span className="number">{NNumberedObjects}</span>
                <span className="unit">{t('個')}</span>
              </p>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('もっと詳しいデータを見る')}
              </p>
              <div className="data_analysis-detail-buttons">
                <Button
                  className="f-button"
                  variant="secondary"
                  onClick={() => navigate('/data_analysis/candidates')}
                >
                  {t('新天体の候補')}
                </Button>
                <Button
                  className="f-button"
                  variant="secondary"
                  onClick={() => navigate('/data_analysis/provisional')}
                >
                  {t('仮符号天体')}
                </Button>
                <Button
                  className="f-button"
                  variant="secondary"
                  onClick={() => navigate('/data_analysis/numbered')}
                >
                  {t('確定番号天体')}
                </Button>
                <Button
                  className="f-button"
                  variant="secondary"
                  onClick={() => navigate('/data_analysis/comets')}
                >
                  {t('彗星')}
                </Button>
              </div>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIAS測定天体の等級ヒストグラム')}
              </p>
              <MagHistogram magHistogramData={magHistogramData} />
              <span className="mg-r_xs f-s14_w500 glossary_wrap-text">
                {t('「確定番号天体」「仮符号天体」の意味については')}
                <NavLink
                  to="/details/procedure"
                  style={{ textDecoration: 'underline' }}
                >
                  {t('「小惑星を発見するには？」')}
                </NavLink>
                {t('のページをご覧ください。')}
              </span>
              <br />
              <span className="mg-r_xs f-s14_w500">
                {t(
                  '(注) ヒストグラムに記載した直径はあくまでも目安です (小惑星の太陽からの距離2.5au、反射率0.15、位相角0度を仮定した見積もり)。またフィルターの違いは考慮しておらず、様々なフィルターで得られた等級を使用しています。',
                )}
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default DataAnalysis;
